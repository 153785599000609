<template>
	<div class="row">
		<div class="col-md-5 m-auto">
			<div class="box">
				<div v-if="printers.length">
					<h2>Setup Printer</h2>
					<p class="text-black-50">Please select printers to print labels and barcodes.</p>
					<div class="row">
						<div class="col-sm-8">
							<div class="mb-4">
								<label for="">Label Printer</label>
								<b-form-select v-model="label_printer" :options="printers"></b-form-select>
							</div>
							<div class="mb-4">
								<label for="mb-4">Barcode Printer</label>
								<b-form-select v-model="barcode_printer" :options="printers"></b-form-select>
							</div>
							<div class="">
								<label for="mb-4">Seller Doc Printer</label>
								<b-form-select v-model="seller_doc_printer" :options="printers"></b-form-select>
							</div>
						</div>
					</div>

					<div>
						<div v-if="saved" class="text-success mt-4">Printer setup done.</div>
						<b-button v-else color="success" class="mt-4" @click="saveSetting">Save Settings</b-button>
					</div>
				</div>
				<div v-else class="mt-4 text-center">
					<h2 class="text-center">Setup Printer</h2>
					<p class="text-danger">JSPrintManager (JSPM) is not installed or not running in this computer!</p>
					<a href="https://neodynamic.com/downloads/jspm" target="_new">Download JSPM Client App</a>
					<div class="m-3 text-muted">Waiting... <b-spinner small class="ml-1"></b-spinner></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AssignPrinter",
	props: ['printers'],
	data() {
		return {
			saved: false,
			label_printer: this.$store.getters.global.label_printer || null,
			barcode_printer: this.$store.getters.global.barcode_printer || null,
			seller_doc_printer: this.$store.getters.global.seller_doc_printer || null,

		}
	},
	methods: {
		saveSetting() {

			this.$store.commit('setGlobal', {
				'barcode_printer': this.barcode_printer, 
				'label_printer': this.label_printer,
				'seller_doc_printer': this.seller_doc_printer,
			})
			this.saved = true
			setTimeout(() => {
				this.$router.go()
			}, 1000);
		}
	}
}
</script>